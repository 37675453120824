.back-button {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  padding: 4px 10px;
  font-size: 14px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color:#000000;
  gap: 8px;
  transition:
    background-color 0.3s,
    box-shadow 0.3s,
    transform 0.1s;
}
.back-button::before {
  content: "←";
  font-size: 18px;
}
.back-button:hover {
  background-color: #f5f5f5;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
}
.back-button:active {
  background-color: #e0e0e0;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  transform: translateY(1px);
}

.container {
  padding: 20px 20px;
}

.market-insights-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 48px;
  align-items: stretch;
}

.column-start{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.card {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease;
  padding: 16px;
  text-align: center;
  cursor: pointer;
}

.bold {
  font-weight: bold;
}

.reduced-margin-list {
  padding-left: 28px;
  margin-left: 0;
}

.market-info-grid {
  display: grid;
  grid-template-areas:
    "icon title"
    "icon metrics";
  grid-template-columns: auto 1fr;
  align-items: start;
  grid-gap: 16px;

  .icon {
    grid-area: icon;
  }

  .title {
    grid-area: title;
  }

  .metrics {
    grid-area: metrics;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 1300px) {
  .market-info-grid {
    .metrics {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      grid-gap: 8px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .market-info-grid {
    grid-template-areas: "icon title" "metrics metrics";

    .metrics {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
