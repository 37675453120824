@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@300;400;500&display=swap');

* {
	font-family: 'Poppins', sans-serif;
	font-weight: lighter;
	box-sizing: border-box;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

html,
body {
	font-size: 16px;
}

.link-unstyled,
.link-unstyled:link,
.link-unstyled:hover {
	color: inherit;
	text-decoration: inherit;
}

.loader,
.loader:after {
	border-radius: 50%;
	width: 10em;
	height: 10em;
}
.loader {
	margin: 60px auto;
	font-size: 10px;
	position: relative;
	text-indent: -9999em;
	border-top: 1.1em solid rgba(25, 150, 224, 0.2);
	border-right: 1.1em solid rgba(25, 150, 224, 0.2);
	border-bottom: 1.1em solid rgba(25, 150, 224, 0.2);
	border-left: 1.1em solid #1996e0;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load8 1.1s infinite linear;
	animation: load8 1.1s infinite linear;
}

.clickable {
	cursor: pointer;
}

.flex-column {
	display: flex;
	flex-direction: column;
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.space-between {
	justify-content: space-between;
}

.markets-controls {
	justify-content: space-between;
	align-items: center;
}

.markets-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	gap: 16px;
	align-items: stretch;
}

.market-card {
	background-color: #ffffff;
	border: 1px solid #e0e0e0;
	border-radius: 6px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	transition: transform 0.2s ease, box-shadow 0.2s ease;
	padding: 16px;
	text-align: center;
	cursor: pointer;
	text-decoration: none; /* Ensures no underline */
	color: inherit; /* Inherits text color to prevent link color */
	display: block; /* Ensures the entire card is clickable */
}

.market-card:hover {
	transform: translateY(-3px);
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.market-card:active {
	transform: translateY(-1px);
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12);
}


.market-icon {
	height: 40px;
	width: 40px;
	border-radius: 4px;
	object-fit: cover
}

.market-icon-large {
	height: 100px;
	width: 100px;
	border-radius: 4px;
	object-fit: cover
}

.market-title {
	font-weight: 600;
	font-size: 14px;
}

.market-title-large {
	font-weight: 600;
	font-size: 26px;
	text-align: left;
}

.description-text {
	color: rgba(0,0,0,.6);
	text-align: left;
}

.market-volume {
	font-size: 12px;
	font-weight: 400;
}

.input-container {
}

.styled-input {
	width: 100%;
	padding: 12px 16px;
	font-size: 16px;
	border: 1px solid #ccc;
	border-radius: 20px;
	outline: none;
	transition: all 0.2s ease-in-out;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.styled-input:focus {
	border-color: #2dbcb8;
	box-shadow: 0 4px 8px rgba(98, 0, 234, 0.2);
}

